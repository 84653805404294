import * as React from 'react'

function SvgComponent(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
      fill="currentColor"
      {...props}
    >
      <path d="M183.9 370.1a15.9 15.9 0 00-21.8 6l-64 112a16 16 0 1027.8 15.8l64-112a16 16 0 00-6-21.8zM279.9 370.1a15.9 15.9 0 00-21.8 6l-64 112a16 16 0 1027.8 15.8l64-112a16 16 0 00-6-21.8zM87.9 370.1a15.9 15.9 0 00-21.8 6l-64 112a16 16 0 1027.8 15.8l64-112a16 16 0 00-6-21.8zM471.9 370.1a15.9 15.9 0 00-21.8 6l-64 112a16 16 0 1027.8 15.8l64-112a16 16 0 00-6-21.8zM375.9 370.1a15.9 15.9 0 00-21.8 6l-64 112a16 16 0 1027.8 15.8l64-112a16 16 0 00-6-21.8zM170.7 153.9c-5.6 8.3-3.3 20.1 4.1 26.2 12.9 9.9 25.1-4.1 23.5-16.4-.2-15-19.1-23.1-27.6-9.8z" />
      <path d="M416 128l-1.6.2C432.2 48.3 325.6-2.6 275 60.8 219.1-52.3 40.5 8.2 66.1 133.2 27.8 145.8 0 181.5 0 224a96 96 0 0096 96h320c126.1-2.1 126.2-189.8 0-192zm-175.3-5.8c11 4.6 16.1 23.2 3.8 29.2a215.1 215.1 0 0020.7 14.7c-21.4 9.4-14 5.9-32.9-9.3l-.3.3c1.9 6.6 3.8 13.2 5.7 20l-13.2 5.5c-5.6-19.4-11-38.7-16.4-58.1 10.6-1.7 22.1-6.8 32.6-2.3zM184.9 200c-37.3 2.6-45.2-63.7-6.1-68.9s48.8 62.9 6.1 68.9zm12.8-153.5c16.9.6 16.8-4.4 20.4 13.8l-11.4-.2c4.3 15.8 8.7 31.4 13 47l-13.9 2.3c-4.5-16.1-8.9-32-13.3-48-4 .2-7.7 1.1-11.7 1.7-1.3-4.6-2.5-9.2-3.8-14a130.2 130.2 0 0120.7-2.6zM174 49.8q8.7 31.4 17.5 62.7l-13.9 3.9c-5.9-20.9-11.7-41.8-17.6-62.7a116.5 116.5 0 0114-3.9zm-52.8 22.5c2.3 7.4 4.3 14.7 6.5 22l18.5-9-6.6-23.3a140.9 140.9 0 0113.3-5.8c.6 1 17.2 59.6 17.6 62.5l-13.5 4.8-7.1-25.3c-6.4 2.6-12.5 5.4-18.7 8.5l6.9 24.4-12.7 5.7c-5.4-18.7-10.8-37.3-16.3-56.1 4-3 7.9-5.7 12.1-8.4zm10.7 143.6l-16.4-58.8c9.1-4 18.2-7.8 27.6-11.5 1.3 4.6 2.6 9.1 3.8 13.6l-15.4 5.9c.9 3.3 1.8 6.4 2.7 9.8 4.8-1.4 9.3-3.3 14.1-4.9 1.3 4.5 2.5 9.1 3.8 13.7l-14.1 4.8 6.6 24.1c-4.2 1.3-8.4 2.3-12.7 3.3zm64.4 51c-1.4 16.4-18.9 28.1-34.7 19.1l2-14.8c13.8 9.7 25-4.8 14.3-9.5-7.5-1.4-16.9.8-21.6-6.9-12-21.5 2.4-40 26.1-34.5l-1.8 14.5c-10.6-5.6-20.9 6.4-9.7 10 16.3-1 26.4 4.3 25.4 22.1zm9 7.2c-7-19.5-11.3-40.1-17.4-59.9l13.5-5.8 16.6 58zm51.8-37.7c-3.7 1.6-11.4-5.2-15.6-6.6-6 20.5-1 23.5-21.5 35 3.3-13.7 7-27.3 10.7-41.1a271.4 271.4 0 01-23.7-17.8l16.4-7.5 11.5 9.9c4.4-19.4.5-16.3 19.3-24.8-2.9 10.4-5.7 20.5-8.4 30.9a82.1 82.1 0 0024.2 9.8c-4.1 4.3-8.6 8-12.9 12.2z" />
      <path d="M237.8 140.8c.9-7-7.3-10.1-12.6-7.3 1.3 4.5 2.6 8.9 3.9 13.6 3.6-.9 8-2.1 8.7-6.3z" />
    </svg>
  )
}

export default SvgComponent
